import { Input } from "../../components/Input/Input";
import { Label } from "../../components/Label/Label";
import { useState } from "react";
import styles from "./Login.module.scss";
import GLOBALS from "../../constants/Globals";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Login=()=>{
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [errorMessage, setErrorMessage] = useState<string>();
    const navigate = useNavigate();

    const handleLoginSubmit=async ()=>{
        console.log(email, password);
        try {console.log({username:email, password:password});
            const response = await axios.post(GLOBALS.API_BASE_URL+"auth/login", {username:email, password:password});
            localStorage.setItem("token", response.data.token);
            setErrorMessage("");
            navigate("/home");
            console.log(response);
        } catch (error) {
            setErrorMessage("Invalid Credentials");
        }
    }
    return (
        <div className={styles.loginContainer}>
            <div className={styles.loginForm}>
                <h2 className={styles.loginFormTile}>{GLOBALS.COMPANY_NAME}</h2>
                <h2>Login</h2>
                <div>{errorMessage}</div>
                <div className={styles.formGroup}>
                    <Label label="Email" />
                    <Input type="text" inputChange={(value:any) => setEmail(value)} />
                </div>
                <div className={styles.formGroup}>
                    <Label label="Password" />
                    <Input type="password" inputChange={(value:any) => setPassword(value)} />
                </div>
                <button onClick={handleLoginSubmit}>Login</button>
                <a href="#" className={styles.forgotPassword}>Forgot Password?</a>
            </div>
        </div>
    );
}

export default Login